import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.42.0/Interop.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.42.0/React.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";
import { singleton, append, delay, toList } from "./.fable/fable-library.3.2.10/Seq.js";
import { isEmpty } from "./.fable/fable-library.3.2.10/List.js";
import { Home } from "./Home.js";

function navBar() {
    return createElement("div", {
        className: "landing-header",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "heading-fade",
            }), createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 1.5 + "rem",
                    },
                    children: "Quasar AS",
                })]),
            }), createElement("div", {
                style: {},
                children: Interop_reactApi.Children.toArray([]),
            })]),
        })]),
    });
}

const notFoundView = createElement("div", {
    style: {
        display: "flex",
        width: 100 + "vw",
        height: 100 + "vh",
        justifyContent: "center",
        alignItems: "center",
    },
    children: Interop_reactApi.Children.toArray([createElement("h3", {
        className: "title is-3",
        children: "404 - Page not found",
    })]),
});

function infobar() {
    return createElement("div", {
        style: {
            padding: 10,
            textAlign: "center",
            color: "#9a9a9a",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: "Quasar AS, Org.nr 927446162",
        })]),
    });
}

export function App() {
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const children = toList(delay(() => append(singleton(RouterModule_router({
        onUrlChanged: patternInput[1],
    })), delay(() => append(singleton(navBar()), delay(() => append(isEmpty(patternInput[0]) ? singleton(createElement(Home, null)) : singleton(notFoundView), delay(() => singleton(infobar())))))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

