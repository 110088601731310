import { Union, Record } from "./.fable/fable-library.3.2.10/Types.js";
import { union_type, record_type, option_type, string_type } from "./.fable/fable-library.3.2.10/Reflection.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.42.0/Interop.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "./.fable/Feliz.1.42.0/React.fs.js";

class Model extends Record {
    constructor(ErrorMsg) {
        super();
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Home.Model", [], Model, () => [["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DismissError"];
    }
}

function Message$reflection() {
    return union_type("Home.Message", [], Message, () => [[]]);
}

function init() {
    return new Model(void 0);
}

function update(model, msg) {
    return new Model(void 0);
}

function madeBy(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            padding: ((90 + "px ") + 30) + "px",
            backgroundColor: "var(--quasar-main)",
            color: "var(--bg-main)",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                color: "#9a9a9a",
                fontSize: 0.8 + "rem",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: "Made by Quasar AS",
            })]),
        }), createElement("div", {
            style: {
                display: "flex",
                flexDirection: "column",
                padding: ((0 + "px ") + 30) + "px",
                marginTop: 8,
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.5 + "rem",
                    color: "#FF8C00",
                },
                children: "Investfora",
            }), createElement("p", {
                style: {},
                children: "Investfora is a social investment platform where users can share their thoughts, outlooks and target price for hundreds of stocks listed at multiple exchanges around the world.",
            }), createElement("a", {
                href: "https://investfora.com",
                target: "_blank",
                style: {
                    fontSize: 0.8 + "rem",
                    color: "var(--bg-main)",
                },
                children: "Visit investfora.com",
            })]),
        }), createElement("div", {
            style: {},
            children: Interop_reactApi.Children.toArray([]),
        })]),
    });
}

function head(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: "Software and platforms, on web and mobile, for finance, stocks and other investment.",
        })]),
    });
}

function bio(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 30,
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("p", {
            children: "Quasar AS is a norwegian company established 2021.",
        })]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([head(model, dispatch), madeBy(model, dispatch), bio(model, dispatch)]),
    });
}

export function Home() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    return view(patternInput[0], patternInput[1]);
}

